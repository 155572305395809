$az-spacing-zero: 0;
$az-spacing-point-one: 0.1px;
$az-spacing-point-four: 0.4px;
$az-spacing-half: 0.5px;
$az-spacing-point-six: 0.6px;
$az-spacing-point-sixty-eight: 0.68px;
$az-spacing-three-fourths: 0.75px;
$az-spacing-1: 1px;
$az-spacing-one-point-33: 1.33px;
$az-spacing-one-point-five: 1.5px;
$az-spacing-2: 2px;
$az-spacing-3: 3px;
$az-spacing-three-point-eight-four: 3.84375px;
$az-spacing-5xs: 4px;
$az-spacing-5xs-point-5: 4.5px;
$az-spacing-5: 5px;
$az-spacing-5-point-5: 5.5px;
$az-spacing-6: 6px;
$az-spacing-7: 7px;
$az-spacing-7-point-62: 7.62px;
$az-spacing-4xs: 8px;
$az-spacing-8-point-12: 8.12px;
$az-spacing-9: 9px;
$az-spacing-10: 10px;
$az-spacing-11: 11px;
$az-spacing-xxxs: 12px;
$az-spacing-13: 13px;
$az-spacing-14: 14px;
$az-spacing-15: 15px;
$az-spacing-15-75: 15.75px;
$az-spacing-xxs: 16px;
$az-spacing-17: 17px;
$az-spacing-18: 18px;
$az-spacing-19: 19px;
$az-spacing-xs: 20px;
$az-spacing-21: 21px;
$az-spacing-22: 22px;
$az-spacing-22-5: 22.5px;
$az-spacing-23: 23px;
$az-spacing-s: 24px;
$az-spacing-24-4: 24.4px;
$az-spacing-25: 25px;
$az-spacing-26: 26px;
$az-spacing-27: 27px;
$az-spacing-28: 28px;
$az-spacing-29: 29px;
$az-spacing-30: 30px;
$az-spacing-31: 31px;
$az-spacing-m: 32px;
$az-spacing-33: 33px;
$az-spacing-34: 34px;
$az-spacing-35: 35px;
$az-spacing-36: 36px;
$az-spacing-37: 37px;
$az-spacing-38: 38px;
$az-spacing-39: 39px;
$az-spacing-l: 40px;
$az-spacing-41: 41px;
$az-spacing-42: 42px;
$az-spacing-43: 43px;
$az-spacing-44: 44px;
$az-spacing-45: 45px;
$az-spacing-46: 46px;
$az-spacing-47: 47px;
$az-spacing-xl: 48px;
$az-spacing-49: 49px;
$az-spacing-50: 50px;
$az-spacing-51: 51px;
$az-spacing-52: 52px;
$az-spacing-53: 53px;
$az-spacing-54: 54px;
$az-spacing-55: 55px;
$az-spacing-xxl: 56px;
$az-spacing-57: 57px;
$az-spacing-58: 58px;
$az-spacing-59: 59px;
$az-spacing-60: 60px;
$az-spacing-61: 61px;
$az-spacing-62: 62px;
$az-spacing-63: 63px;
$az-spacing-xxxl: 64px;
$az-spacing-65: 65px;
$az-spacing-66: 66px;
$az-spacing-67-point-6: 67.6px;
$az-spacing-67: 67px;
$az-spacing-68: 68px;
$az-spacing-68-point-2: 68.2px;
$az-spacing-69: 69px;
$az-spacing-70: 70px;
$az-spacing-71: 71px;
$az-spacing-72: 72px;
$az-spacing-75: 75px;
$az-spacing-75-point-2: 75.2px;
$az-spacing-76: 76px;
$az-spacing-77: 77px;
$az-spacing-78: 78px;
$az-spacing-79: 79px;
$az-spacing-4xl: 80px;
$az-spacing-83: 83px;
$az-spacing-84: 84px;
$az-spacing-85: 85px;
$az-spacing-86: 86px;
$az-spacing-87: 87px;
$az-spacing-88: 88px;
$az-spacing-90: 90px;
$az-spacing-91: 91px;
$az-spacing-92: 92px;
$az-spacing-93: 93px;
$az-spacing-5xl: 96px;
$az-spacing-97: 97px;
$az-spacing-100: 100px;
$az-spacing-104: 104px;
$az-spacing-105: 105px;
$az-spacing-106: 106px;
$az-spacing-107: 107px;
$az-spacing-108: 108px;
$az-spacing-110: 110px;
$az-spacing-111: 111px;
$az-spacing-112: 112px;
$az-spacing-113: 113px;
$az-spacing-114: 114px;
$az-spacing-115: 115px;
$az-spacing-119: 119px;
$az-spacing-120: 120px;
$az-spacing-121: 121px;
$az-spacing-124: 124px;
$az-spacing-125: 125px;
$az-spacing-127: 127px;
$az-spacing-6xl: 128px;
$az-spacing-130: 130px;
$az-spacing-132: 132px;
$az-spacing-133: 133px;
$az-spacing-134: 134px;
$az-spacing-135: 135px;
$az-spacing-136: 136px;
$az-spacing-137: 137px;
$az-spacing-138: 138px;
$az-spacing-140: 140px;
$az-spacing-142: 142px;
$az-spacing-143: 143px;
$az-spacing-144: 144px;
$az-spacing-145: 145px;
$az-spacing-146: 146px;
$az-spacing-148: 148px;
$az-spacing-150: 150px;
$az-spacing-151: 151px;
$az-spacing-152: 152px;
$az-spacing-153: 153px;
$az-spacing-156: 156px;
$az-spacing-158: 158px;
$az-spacing-160: 160px;
$az-spacing-162: 162px;
$az-spacing-163: 163px;
$az-spacing-164: 164px;
$az-spacing-165: 165px;
$az-spacing-170: 170px;
$az-spacing-171: 171px;
$az-spacing-172: 172px;
$az-spacing-174: 174px;
$az-spacing-175: 175px;
$az-spacing-176: 176px;
$az-spacing-178: 178px;
$az-spacing-179: 179px;
$az-spacing-180: 180px;
$az-spacing-183: 183px;
$az-spacing-184: 184px;
$az-spacing-185: 185px;
$az-spacing-186: 186px;
$az-spacing-187: 187px;
$az-spacing-189: 189px;
$az-spacing-190: 190px;
$az-spacing-193: 193px;
$az-spacing-196: 196px;
$az-spacing-198: 198px;
$az-spacing-200: 200px;
$az-spacing-201: 201px;
$az-spacing-204: 204px;
$az-spacing-208: 208px;
$az-spacing-209: 209px;
$az-spacing-210: 210px;
$az-spacing-211: 211px;
$az-spacing-212: 212px;
$az-spacing-215: 215px;
$az-spacing-216: 216px;
$az-spacing-217: 217px;
$az-spacing-220: 220px;
$az-spacing-223: 223px;
$az-spacing-225: 225px;
$az-spacing-224: 224px;
$az-spacing-226: 226px;
$az-spacing-230: 230px;
$az-spacing-234: 234px;
$az-spacing-236: 236px;
$az-spacing-237: 237px;
$az-spacing-238: 238px;
$az-spacing-240: 240px;
$az-spacing-241: 241px;
$az-spacing-244: 244px;
$az-spacing-248: 248px;
$az-spacing-250: 250px;
$az-spacing-252: 252px;
$az-spacing-256: 256px;
$az-spacing-257: 257px;
$az-spacing-258: 258px;
$az-spacing-259: 259px;
$az-spacing-260: 260px;
$az-spacing-261: 261px;
$az-spacing-262: 262px;
$az-spacing-263: 263px;
$az-spacing-264: 264px;
$az-spacing-265: 265px;
$az-spacing-270: 270px;
$az-spacing-271: 271px;
$az-spacing-272: 272px;
$az-spacing-275: 275px;
$az-spacing-278: 278px;
$az-spacing-280: 280px;
$az-spacing-282: 282px;
$az-spacing-285: 285px;
$az-spacing-286: 286px;
$az-spacing-287: 287px;
$az-spacing-288: 288px;
$az-spacing-290: 290px;
$az-spacing-294: 294px;
$az-spacing-295: 295px;
$az-spacing-300: 300px;
$az-spacing-301: 301px;
$az-spacing-305: 305px;
$az-spacing-310: 310px;
$az-spacing-316: 316px;
$az-spacing-317: 317px;
$az-spacing-320: 320px;
$az-spacing-324: 324px;
$az-spacing-330: 330px;
$az-spacing-331: 331px;
$az-spacing-332: 332px;
$az-spacing-335: 335px;
$az-spacing-336: 336px;
$az-spacing-340: 340px;
$az-spacing-343: 343px;
$az-spacing-350: 350px;
$az-spacing-352: 352px;
$az-spacing-354: 354px;
$az-spacing-355: 355px;
$az-spacing-356: 356px;
$az-spacing-360: 360px;
$az-spacing-362: 362px;
$az-spacing-370: 370px;
$az-spacing-372: 372px;
$az-spacing-375: 375px;
$az-spacing-376: 376px;
$az-spacing-380: 380px;
$az-spacing-386: 386px;
$az-spacing-392: 392px;
$az-spacing-393: 393px;
$az-spacing-394: 394px;
$az-spacing-397: 397px;
$az-spacing-400: 400px;
$az-spacing-405: 405px;
$az-spacing-409: 409px;
$az-spacing-410: 410px;
$az-spacing-415: 415px;
$az-spacing-416: 416px;
$az-spacing-420: 420px;
$az-spacing-424: 424px;
$az-spacing-426: 426px;
$az-spacing-430: 430px;
$az-spacing-438: 438px;
$az-spacing-441: 441px;
$az-spacing-449: 449px;
$az-spacing-450: 450px;
$az-spacing-455: 455px;
$az-spacing-459: 459px;
$az-spacing-460: 460px;
$az-spacing-462: 462px;
$az-spacing-465: 465px;
$az-spacing-466: 466px;
$az-spacing-470: 470px;
$az-spacing-475: 475px;
$az-spacing-480: 480px;
$az-spacing-500: 500px;
$az-spacing-512: 512px;
$az-spacing-520: 520px;
$az-spacing-527: 527px;
$az-spacing-530: 530px;
$az-spacing-531: 531px;
$az-spacing-537: 537px;
$az-spacing-540: 540px;
$az-spacing-542: 542px;
$az-spacing-543: 543px;
$az-spacing-535: 535px;
$az-spacing-550: 550px;
$az-spacing-560: 560px;
$az-spacing-574: 574px;
$az-spacing-575: 575px;
$az-spacing-590: 590px;
$az-spacing-592: 592px;
$az-spacing-600: 600px;
$az-spacing-602: 602px;
$az-spacing-610: 610px;
$az-spacing-620: 620px;
$az-spacing-623: 623px;
$az-spacing-631: 631px;
$az-spacing-640: 640px;
$az-spacing-650: 650px;
$az-spacing-654: 654px;
$az-spacing-680: 680px;
$az-spacing-688: 688px;
$az-spacing-700: 700px;
$az-spacing-720: 720px;
$az-spacing-728: 728px;
$az-spacing-800: 800px;
$az-spacing-802: 802px;
$az-spacing-825: 825px;
$az-spacing-825: 825px;
$az-spacing-900: 900px;
$az-spacing-914: 914px;
$az-spacing-1022: 1022px;
$az-spacing-1024: 1024px;
$az-spacing-1100: 1100px;
$az-spacing-1170: 1170px;
$az-spacing-1440: 1440px;
$az-spacing-1600: 1600px;
$az-spacing-1560: 1560px;
$az-spacing-neg-1: -1px;
$az-spacing-neg-2: -2px;
$az-spacing-neg-3: -3px;
$az-spacing-neg-4: -4px;
$az-spacing-neg-5: -5px;
$az-spacing-neg-6: -6px;
$az-spacing-neg-7: -7px;
$az-spacing-neg-8: -8px;
$az-spacing-neg-9: -9px;
$az-spacing-neg-10: -10px;
$az-spacing-neg-11: -11px;
$az-spacing-neg-12: -12px;
$az-spacing-neg-13: -13px;
$az-spacing-neg-14: -14px;
$az-spacing-neg-15: -15px;
$az-spacing-neg-16: -16px;
$az-spacing-neg-18: -18px;
$az-spacing-neg-20: -20px;
$az-spacing-neg-22: -22px;
$az-spacing-neg-23: -23px;
$az-spacing-neg-24: -24px;
$az-spacing-neg-25: -25px;
$az-spacing-neg-29: -29px;
$az-spacing-neg-30: -30px;
$az-spacing-neg-32: -32px;
$az-spacing-neg-35: -35px;
$az-spacing-neg-40: -40px;
$az-spacing-neg-50: -50px;
$az-spacing-neg-60: -60px;
$az-spacing-neg-70: -70px;
$az-spacing-neg-88: -88px;
$az-spacing-neg-94: -94px;
$az-spacing-neg-140: -140px;
$az-spacing-neg-150: -150px;
$az-spacing-neg-297: -297px;
$az-spacing-neg-10k: -10000px;

$az-spacing-rem-0-point-02: 0.02rem;
$az-spacing-rem-1: 1rem;
$az-spacing-rem-1-point-5: 1.5rem;
$az-spacing-rem-2: 2rem;
$az-spacing-rem-3: 3rem;
$az-spacing-rem-4: 4rem;
$az-spacing-rem-5: 5rem;
$az-spacing-rem-6: 6rem;
$az-spacing-rem-7: 7rem;
$az-spacing-rem-8: 8rem;
$az-spacing-rem-9: 9rem;
$az-spacing-rem-10: 10rem;
$az-spacing-rem-11: 11rem;
$az-spacing-rem-12: 12rem;
$az-spacing-rem-13: 13rem;
$az-spacing-rem-14: 14rem;
$az-spacing-rem-14: 14rem;
$az-spacing-rem-14: 14rem;
$az-spacing-rem-14: 14rem;
$az-spacing-rem-15: 15rem;

$az-spacing-neg-rem-3: -3rem;
