@import '@/theme/newVariables';

.STARCTypography {
  --st-font-size-root: 16px;
  --st-font-size-062: calc(0.625 * var(--st-font-size-100));
  --st-font-size-075: calc(0.75 * var(--st-font-size-100));
  --st-font-size-087: calc(0.875 * var(--st-font-size-100));
  --st-font-size-100: var(--st-font-size-root, 1rem);
  --st-font-size-112: calc(1.125 * var(--st-font-size-100));
  --st-font-size-125: calc(1.25 * var(--st-font-size-100));
  --st-font-size-137: calc(1.375 * var(--st-font-size-100));
  --st-font-size-150: calc(1.5 * var(--st-font-size-100));
  --st-font-size-175: calc(1.75 * var(--st-font-size-100));
  --st-font-size-200: calc(2 * var(--st-font-size-100));
  --st-font-size-225: calc(2.25 * var(--st-font-size-100));
  --st-font-size-250: calc(2.5 * var(--st-font-size-100));
  --st-font-size-275: calc(2.75 * var(--st-font-size-100));
  --st-font-size-300: calc(3 * var(--st-font-size-100));
  --st-font-size-350: calc(3.5 * var(--st-font-size-100));

  @media #{$extra-large-and-up} {
    --st-font-size-root: 18px;
  }
}
