@import '../../../../theme/newVariables.scss';

.autoComplete {
  height: $az-percent-100;

  .labelItem {
    font-weight: bold;
    pointer-events: none;
    cursor: default;
  }

  .listStyle {
    background-color: $az-white;
    box-shadow: $az-box-shadow-gray-3;
    position: absolute;
    left: $az-spacing-zero;
    max-height: $az-spacing-280;
    overflow-y: scroll;
    padding: $az-spacing-10 $az-spacing-zero;
    width: $az-percent-100;
    height: auto;
    text-align: $ldirection;
    top: $az-spacing-58;
    z-index: 3;

    @media #{$medium-and-down} {
      top: $az-spacing-62;
    }

    @media only screen and (max-height: $small-screen) {
      max-height: 180px;
    }

    ul {
      margin: $az-spacing-zero;
    }
  }

  .listItem {
    border: none;
    color: $az-grey-7;
    width: $az-percent-100;
    background-color: transparent;
    padding: $az-spacing-4xs $az-spacing-xxs;
    text-align: $ldirection;
    cursor: pointer;

    &:hover {
      background-color: $az-grey-1;
      font-weight: bold;
    }
  }

  .activeItem {
    background-color: $az-grey-1;
  }

  .arrowButtonBase {
    position: absolute;
    padding: $az-spacing-zero;
    right: $az-spacing-xxs;
    top: $az-percent-50;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 0;

    &:hover {
      background-color: transparent;
    }
    .desktopDropdownIcon {
      width: $az-spacing-s;
      transition: transform 200ms;
    }
    &.arrowButtonDisabled {
      cursor: auto;
      .desktopDropdownIcon {
        color: $az-grey-14;
      }
    }
    &.arrowUpPosition {
      .desktopDropdownIcon {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
      }
    }
  }

  .divider {
    height: $az-spacing-50;
    background-color: $az-grey-1;
    right: $az-spacing-zero;
    position: absolute;
    top: $az-spacing-xxxs;
    width: $az-spacing-1;
  }

  .desktopInput {
    border: 1px solid $az-black;
    height: $az-spacing-xxl;
    color: $az-grey-14;
    border-radius: $az-spacing-6;
    background-color: $az-white;
    &.disabled {
      background-color: $az-grey-1;
      border-color: $az-medium-grey;
    }
    &.focused {
      border-bottom: $az-spacing-2 solid $az-medium-orange !important;
    }
  }
  .inputSec {
    width: $az-percent-100;
    position: absolute;
    top: $az-spacing-zero;
    border-radius: $az-spacing-6;

    .inputStyles,
    .suggestLabel {
      font-family: $font-az-helvetica;
      font-size: $font-size-16;
      letter-spacing: normal;
      line-height: 1.5;
    }

    .inputStyles {
      color: $az-black !important;
      padding: $az-spacing-30 $az-spacing-36 $az-spacing-xxs $az-spacing-xxs;
      text-overflow: ellipsis;
    }

    .suggestLabel {
      color: $az-grey-5;
      top: $az-spacing-neg-8;
      left: $az-spacing-xxs;
      cursor: pointer;
    }

    .suggestLabelDisabled {
      color: $az-medium-grey;
    }
    .suggestLabelShrink {
      left: $az-spacing-xxs;
      top: $az-spacing-5xs;
    }
  }
}

.nativeSelectRoot {
  width: $az-percent-100;
  .mobileArrowDownIcon {
    top: 50%;
    transform: translateY(-50%);
    right: $az-spacing-19;
    color: $az-black;
  }
  .ymmeNativeSelectInput {
    border: $az-spacing-1 solid $az-grey-3;
    color: $az-black !important;
    border-radius: $az-spacing-5xs;
    padding: $az-spacing-zero $az-spacing-44 $az-spacing-zero $az-spacing-18;
    height: $az-spacing-60;
    @media #{$small-and-down} {
      height: $az-spacing-54;
    }
    &.focused,
    &:focus {
      border-bottom: $az-spacing-2 solid $az-medium-orange !important;
      background-color: $az-white;
      border-color: $az-black;
    }
    &:disabled {
      background-color: $az-grey-1;
      color: $az-medium-grey !important;
    }
  }
}
