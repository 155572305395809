@import '@/theme/newVariables.scss';

$gap: 16px;

.grid-items {
  &-four {
    flex-basis: calc((100% - ($gap * 3)) / 4);

    @media #{$small-and-down} {
      flex-basis: calc((100% - ($gap * 1)) / 2);
    }

    &-lines {
      flex-basis: calc(100% / 4);

      @media #{$small-and-down} {
        flex-basis: calc(100% / 2);
      }
    }
  }

  &-five {
    flex-basis: calc((100% - ($gap * 4)) / 5);

    @media #{$small-and-down} {
      flex-basis: 100%;
    }
    &-lines {
      flex-basis: calc(100% / 5);

      @media #{$small-and-down} {
        flex-basis: 100%;
      }
    }
  }

  &-six {
    flex-basis: calc((100% - ($gap * 5)) / 6);

    @media #{$medium-only} {
      flex-basis: calc((100% - ($gap * 2)) / 3);
    }

    @media #{$small-and-down} {
      flex-basis: calc((100% - ($gap * 1)) / 2);
    }

    &-lines {
      flex-basis: calc(100% / 6);

      @media #{$small-and-down} {
        flex-basis: calc(100% / 2);
      }
    }
  }
}

.imageContainer {
  flex: 1;
  max-height: 160px;

  picture {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.gridImageContainer {
  margin-bottom: 16px;
}

.image {
  object-fit: contain;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  max-height: 228px;
  max-width: 378px;

  @media #{$small-and-down} {
    max-height: 224px;
  }

  &Lines {
    height: 200px;
    justify-content: space-evenly;
    min-width: unset;
  }
}

.gridItemText {
  color: $az-black;
  text-transform: uppercase;
  font-family: $font-az-cond-medium;
  font-size: $font-size-20;
  line-height: $font-size-28;
  text-align: center;

  @media #{$small-and-down} {
    font-size: $font-size-18;
    line-height: $font-size-24;
  }
}

.gridItemTextLines {
  font-size: $font-size-base;
  line-height: unset;
  letter-spacing: 0.038rem;
  @media #{$medium-only} {
    font-size: $font-size-xxs;
  }
}

.gridItemLines {
  background-color: #fff;

  transition: box-shadow 250ms, transform 250ms;

  max-width: none;

  border: solid $az-grey-2;
  border-width: $az-spacing-zero $az-spacing-1 $az-spacing-1 $az-spacing-zero;

  &:hover {
    box-shadow: $az-spacing-zero $az-spacing-2 $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.07),
      $az-spacing-zero $az-spacing-5xs $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.06),
      $az-spacing-zero $az-spacing-1 $az-spacing-6 $az-spacing-zero rgba(0, 0, 0, 0.1);
    transform: scale(1.06);
  }
}
