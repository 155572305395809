$az-white: #fff;
$az-white-2: #d1d1d1;
$az-white-3: #dddcdc;
$az-white-4: #ccc;
$az-white-5: #eee;
$az-grey-0: #f6f6f6;
$az-grey-1: #f2f2f2;
$az-grey-2: #e6e6e6;
$az-grey-3: #a9aaa8;
$az-grey-3-point-5: #767775;
$az-grey-4: #787878;
$az-medium-grey: #949494;
$az-grey-5: #5b5d5b;
$az-grey-6: #484848;
$az-grey-7: #5b5c5d;
$az-grey-8: #7c7c7c;
$az-grey-9: #dadada;
$az-grey-10: #f3f3f3;
$az-grey-11: #767676;
$az-grey-12: #b1b1b3;
$az-grey-13: #3d3d3d;
$az-grey-14: #6e6e6e;
$az-grey-15: #5d5b5d;
$az-grey-16: #d6d6d6;
$az-grey-17: #d7d7d7;
$az-grey-18: #c4c4c4;
$az-grey-19: #cacccb;
$az-grey-20: #f7f7f7;
$az-grey-21: #ddd;
$az-grey-22: #e5e5e5;
$az-dark-black: #000;
$az-black: #202124;
$az-black-1: #202124;
$az-black-2: #333;
$az-light-orange: #f37f00;
$az-light-orange-2: #d36210;
$az-light-orange-3: #fcdfcc;
$az-medium-orange: #f26100;
$az-medium-orange-2: #f26d0d;
$az-dark-orange: #f23f00;
$az-dark-orange-2: #d65600;
$az-dark-orange-3: #d54400;
$az-light-red: #f52c1c;
$az-light-red-2: #fbf2f2;
$az-medium-red: #d52b1e;
$az-dark-red: #971e15;
$az-dark-red-2: #b43034;
$az-hover-red: #860000;
$az-color-success: #1f7400;
$az-green-1: #237804;
$az-green: #88b131;
$az-green-2: #338b2b;
$az-medium-green: #157400;
$az-light-green: #f3f8f2;
$az-color-action-yellow: #ffc800;
$az-color-info: #0050b3;
$az-color-info-alt: #0081b2;
$az-color-error: #b30000;
$az-color-caution: #ffc600;
$az-color-warning: #fac601;
$az-color-warning-light: #fffcf2;
$az-blue: #0060bf;
$az-blue-2: #1474fc;
$az-blue-3: #2ac7e1;
$az-blue-4: #39a9bb;
$az-blue-5: #42b6c9;
$az-blue-6: #007faf;
$az-blue-7: #015fcc;
$az-blue-8: #dfe5ee;
$az-blue-9: #015fcc;
$az-magenta: #aa005b;
$autozone-grey: #666;
$google-pay-grey: #3c4043;

$az-box-shadow-gray-1: 0 1px 4px 0 rgba($az-black, 0.12), 0 2px 2px 0 rgba($az-black, 0.14),
  0 3px 1px -2px rgba($az-black, 0.2);
$az-box-shadow-gray-2: 0 0 2px 0 rgba($az-black, 0.14), 0 2px 2px 0 rgba($az-black, 0.12),
  0 1px 3px 0 rgba($az-black, 0.2);
$az-box-shadow-gray-3: 0 3px 14px 2px rgba($az-black, 0.12), 0 8px 10px 1px rgba($az-black, 0.14),
  0 5px 5px -3px rgba($az-black, 0.2);
$az-box-shadow-gray-4: 3px 2px 4px 0 rgba($az-black, 0.1), inset 1px 0 0 0 $az-grey-3,
  inset -1px 0 0 0 $az-grey-3, inset 0 1px 0 0 $az-grey-3, inset 0 -4px 0 0 $az-black;
$az-box-shadow-gray-5: 0 1px 5px 0 rgba(32, 33, 36, 0.12), 0 2px 2px 0 rgba(32, 33, 36, 0.14),
  0 3px 1px -2px rgba(32, 33, 36, 0.2);
$az-box-shadow-gray-6: 0 1px 3px 0 rgba($az-black, 0.12), 0 1px 1px 0 rgba($az-black, 0.14),
  0 2px 1px -1px rgba($az-black, 0.2);
$az-box-shadow-gray-7: 0 2px 5px 0 rgba(0, 0, 0, 0.24);
$az-box-shadow-gray-8: 0px -3px 5px 0px rgba(0, 0, 0, 0.41);

// Ignored to prevent property-no-unknown errors when adding new colors
/* stylelint-disable property-no-unknown */
:export {
  azwhite: $az-white;
  azgrey1: $az-grey-1;
  azgrey2: $az-grey-2;
  azgrey3: $az-grey-3;
  azgrey4: $az-grey-4;
  azgrey5: $az-grey-5;
  azgrey6: $az-grey-6;
  azgrey7: $az-grey-7;
  azgrey8: $az-grey-8;
  azblack: $az-black;
  azlightorange: $az-light-orange;
  azmediumorange: $az-medium-orange;
  azmediumgrey: $az-medium-grey;
  azdarkorange: $az-dark-orange;
  azlightred: $az-light-red;
  azmediumred: $az-medium-red;
  azdarkred: $az-dark-red;
  azcolorsuccess: $az-color-success;
  azcolorinfo: $az-color-info;
  azcolorinfoalt: $az-color-info-alt;
  azcolorerror: $az-color-error;
  azcolorcaution: $az-color-caution;
}
