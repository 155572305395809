$az-percent-zero: 0%;
$az-percent-1: 1%;
$az-percent-2: 2%;
$az-percent-2-25: 2.25%;
$az-percent-3: 3%;
$az-percent-4: 4%;
$az-percent-5: 5%;
$az-percent-6: 6%;
$az-percent-7: 7%;
$az-percent-8: 8%;
$az-percent-9: 9%;
$az-percent-10: 10%;
$az-percent-12: 12%;
$az-percent-15: 15%;
$az-percent-17: 17%;
$az-percent-18: 18%;
$az-percent-20: 20%;
$az-percent-22: 22%;
$az-percent-25: 25%;
$az-percent-26: 26%;
$az-percent-29: 29%;
$az-percent-30: 30%;
$az-percent-33: 33%;
$az-percent-35: 35%;
$az-percent-36: 36%;
$az-percent-40: 40%;
$az-percent-47: 47%;
$az-percent-48: 48%;
$az-percent-49: 49%;
$az-percent-50: 50%;
$az-percent-52: 52%;
$az-percent-55: 55%;
$az-percent-57: 57%;
$az-percent-58: 58%;
$az-percent-59: 59%;
$az-percent-60: 60%;
$az-percent-61: 61%;
$az-percent-63: 63%;
$az-percent-64: 64%;
$az-percent-65: 65%;
$az-percent-67: 67%;
$az-percent-68: 68%;
$az-percent-70: 70%;
$az-percent-73: 73%;
$az-percent-75: 75%;
$az-percent-78: 78%;
$az-percent-80: 80%;
$az-percent-82: 82%;
$az-percent-84: 84%;
$az-percent-85: 85%;
$az-percent-86: 86%;
$az-percent-88: 88%;
$az-percent-90: 90%;
$az-percent-91: 91%;
$az-percent-92: 92%;
$az-percent-93: 93%;
$az-percent-94: 94%;
$az-percent-95: 95%;
$az-percent-96: 96%;
$az-percent-97: 97%;
$az-percent-98: 98%;
$az-percent-99: 99%;
$az-percent-100: 100%;
$az-percent-101: 101%;
$az-percent-103: 103%;
$az-percent-110: 110%;
$az-percent-140: 140%;
$az-percent-neg-1: -1%;
$az-percent-neg-2: -2%;
$az-percent-neg-3: -3%;
$az-percent-neg-4: -4%;
$az-percent-neg-5: -5%;
$az-percent-neg-8: -8%;
$az-percent-neg-10: -10%;
$az-percent-neg-25: -25%;
$az-percent-neg-27: -27%;
$az-percent-neg-37: -37%;
$az-percent-neg-39: -39%;
$az-percent-neg-44: -44%;
$az-percent-neg-105: -105%;
