@use '@/theme/sharedVariables.module.scss';
@import '@/theme/newVariables.scss';

.carouselAndCaptionContainer,
.carouselContainer {
  display: flex;
  flex: 1;
}

.carouselContainer {
  justify-content: center;
  max-height: $az-spacing-700;
  overflow: auto;
  position: relative;

  &:hover {
    .carouselControl {
      opacity: 1;
    }
  }
}

.carouselAndCaptionContainer {
  flex-direction: column;
}

.carouselImageContainer {
  width: $az-percent-100;
  overflow: hidden;
}

.carouselControl {
  background-color: rgba(242, 242, 242, 0.5);
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
  height: var(--st-unit-11);
  width: var(--st-unit-11);
  padding: 0;
  opacity: 0;

  &:focus {
    background-color: rgba(242, 242, 242, 0.5);
    opacity: 1;
  }
  &.carouselControl:focus ~ .carouselControl {
    opacity: 1;
  }

  &:has(~ .carouselControl:focus) {
    opacity: 1;
  }
}

.carouselControlLeft {
  left: var(--st-unit-4);

  @media #{$small-and-down} {
    left: $az-spacing-zero;
    padding: $az-spacing-zero;
  }
}

.carouselControlRight {
  right: var(--st-unit-4);

  @media #{$small-and-down} {
    right: $az-spacing-zero;
    padding: $az-spacing-zero;
  }
}

.controlImage {
  @media #{$small-and-down} {
    width: $az-spacing-25;
    height: $az-spacing-25;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
}

.carouselSlidesContainer {
  display: flex;
  transition: transform sharedVariables.$az-video-transition-time-milliseconds ease;
  transform: translateX(-100%);
  height: $az-percent-100;

  &.carouselContainerSliding {
    transition: none;
    transform: translateX(0%);
  }

  &.carouselContainerPrev {
    transform: translateX(-200%);
  }

  &.oneChild {
    transform: none;
  }
}

.carouselItem {
  flex: 1 0 $az-percent-100;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $az-spacing-100;
  height: $az-percent-100;
  width: $az-percent-100;
  overflow: hidden;
}

.carouselCaption {
  text-align: $cdirection;
  min-height: $az-spacing-39;
}

.captionSpacer {
  height: 40px;
}

.carouselDot {
  line-height: $az-spacing-zero;
  margin: $az-spacing-2 $az-spacing-zero;
  cursor: pointer;
  height: $az-spacing-10;
  width: $az-spacing-10;
  background-color: $az-grey-2;
  border-radius: $az-percent-50;
  display: inline-block;
  border: $az-spacing-zero;
  padding: $az-spacing-zero;

  &.active {
    background-color: $az-medium-orange !important;
  }

  &:focus {
    background-color: $az-grey-2;
    outline: none;
  }
  &:focus-visible,
  &:focus:active {
    border: $az-spacing-1 solid $az-medium-orange;
  }
}

.carouselDotListItem {
  display: flex;
}

.carouselDotsCenter {
  position: absolute;
  bottom: $az-spacing-xxxs;
  right: $az-spacing-zero;
  left: $az-spacing-zero;
  text-align: center;
}

.carouselDotsList {
  background-color: $az-white;
  border-radius: $az-spacing-xxs;
  display: flex;
  margin: auto;
  padding: $az-spacing-5xs $az-spacing-zero;
  min-width: $az-spacing-100;
  width: fit-content;
  width: -moz-fit-content;
  justify-content: space-around;
  text-align: center;
  z-index: 1;
}
