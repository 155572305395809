@import '@/theme/newVariables.scss';

.thumbnailButton {
  border: $az-spacing-1 solid $az-grey-2;
  padding: $az-spacing-5xs;
  cursor: pointer;
  position: relative;
  width: $az-spacing-70;
  height: $az-spacing-70;
  background: none;
}

.thumbnailButtonSelected {
  border-color: $az-medium-orange;
}

.thumbnailButtonVideo {
  padding: $az-spacing-zero;
}

.thumbnailBox {
  margin: $az-spacing-zero $az-spacing-4xs $az-spacing-xxs $az-spacing-zero;
  display: inline-block;
  vertical-align: middle;
}

.thumbnailGroup {
  margin-top: $az-spacing-4xs;

  &:first-child {
    margin-top: $az-spacing-zero;
  }
}

.thumbnailOverlay {
  height: $az-percent-100;
  width: $az-percent-100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(72, 72, 72, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  color: $az-white;
  line-height: 1;
}

.inlineThumbnailBox {
  &:first-child {
    margin-left: $az-spacing-xxxs;
  }
  vertical-align: top;
  margin-bottom: $az-spacing-zero;
}

.thumbnailsContainer {
  display: block;
  margin: $az-spacing-zero;
}

.thumbnailsContainerHorizontal {
  white-space: nowrap;
  overflow-x: auto;
  margin: $az-spacing-xxs $az-spacing-neg-16;

  &::-webkit-scrollbar {
    display: none;
  }

  &.noScroll {
    overflow-x: hidden;
  }
}

.thumbnailHeading {
  margin-bottom: $az-spacing-xxxs;
}

.thumbnailImage {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
