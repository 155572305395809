@import '@/theme/newVariables.scss';

.gridItemsContainer {
  gap: $az-spacing-m $az-spacing-xxs;
  justify-content: space-between;

  @media #{$small-and-down} {
    justify-content: center;
    padding: 0 $az-spacing-xxs 0 $az-spacing-xxs;
  }
}

.gridItemsContainerLines {
  gap: unset;
  border: solid $az-grey-2;
  border-width: $az-spacing-1 $az-spacing-zero $az-spacing-zero $az-spacing-1;

  @media #{$small-and-down} {
    padding: unset;
  }
}
