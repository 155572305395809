@import '@/theme/newVariables.scss';

.cmsGridBanner {
  display: flex;
  margin-bottom: $az-spacing-l;

  @media #{$small-and-down} {
    flex-direction: column;
  }

  @media #{$medium-and-up} {
    flex-direction: row-reverse;
    position: relative;
    justify-content: space-between;
    aspect-ratio: 2.35;
    height: 100%;
  }

  @media #{$large-and-up} {
    aspect-ratio: 2.95;
    height: 100%;
  }

  .cmsGridLink {
    display: block;
    z-index: 1;

    @media #{$medium-and-up} {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  .contentGrid {
    display: grid;
    row-gap: $az-spacing-xxs;
    column-gap: $az-spacing-xxs;
    padding: $az-spacing-xxs;
    background-color: var(--mobile-background-color);
    height: 100%;
    z-index: 1;

    @media #{$medium-and-up} {
      background-color: transparent;
      aspect-ratio: 1.31;
    }

    @media #{$large-and-up} {
      aspect-ratio: 1.55;
    }
  }

  .contentGridFour {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .contentGridSix {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));

    @media #{$small-and-down} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .backgroundImage {
    display: block;
    width: 100%;
    height: auto;

    @media #{$medium-and-up} {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      object-position: center;
    }
  }
}
