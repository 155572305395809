@import '@/theme/newVariables';

.gridBannerItem {
  background-color: $az-white;
  overflow: hidden;
  padding: $az-spacing-4xs $az-spacing-10;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;

  .imageWrapper {
    min-height: 0px;
    min-width: 0px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    aspect-ratio: 1;

    picture {
      aspect-ratio: 1;
      height: 100%;
      width: auto;
    }
  }

  .item {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .productImage {
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  h1 {
    margin: $az-spacing-4xs 0;
  }

  h3 {
    margin: $az-spacing-4xs 0;
  }
}
