@import '@/theme/newVariables';
.previousButton,
.nextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  padding: 0px;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  &:hover:enabled {
    background: $az-grey-1;
  }
  @media #{$small-and-down} {
    display: none;
  }
}

.previousButton {
  position: absolute;
  top: 161px;
  left: 16px;
}
.nextButton {
  position: absolute;
  top: 161px;
  right: 16px;
}

.heroBannerControl .legalHyperLink,
.heroBannerControl .mediaControl {
  top: 346px !important;
  background-color: none;
  button {
    background: none;
  }
}

.legalHyperLink {
  position: absolute;
  left: 20px;
  top: 351px;
  height: 45px;
  display: flex;
  align-items: center;
  @media #{$small-and-down} {
    left: 16px;
  }
}

.mediaControl {
  position: absolute;
  right: 20px;
  top: 351px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    padding: 0px;
    width: 45px;
    height: 45px;
    background: white;
    &:hover:enabled {
      background: #2021244d;
    }
  }
  @media #{$small-and-down} {
    right: 16px;
  }
}

.raisedControlsPosition {
  top: 328px;
}

.srOnly {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
