@import '@/theme/newVariables.scss';

.button {
  width: auto;

  a {
    color: inherit;
  }
}

.button:hover {
  @media #{$medium-and-down} {
    background-color: $az-black !important;
  }
}
