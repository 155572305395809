@import '@/theme/percentages.scss';
@import '@/theme/newVariables';

@mixin legalPadding {
  // Legal component adds 8px margin top by default, so in the end it's padding: 8px 16px 0 16px
  padding: 0 var(--st-unit-4) 0 var(--st-unit-4);
}

.bannerImage {
  display: flex;
  flex: 1;
  width: $az-percent-100;
  min-height: 70px;
  // Make the image responsive and preserve its original aspect ratio
  height: auto;
}

.dropShadowNone {
  box-shadow: none;
}
.dropShadowSmall {
  box-shadow: var(--st-shadow-2dp);
}
.dropShadowMedium {
  box-shadow: var(--st-shadow-4dp);
}
.dropShadowLarge {
  box-shadow: var(--st-shadow-8dp);
}

.assetSizeXSmall {
  max-height: 100px;

  @media #{$small-and-down} {
    max-height: 200px;
  }
}

.assetSizeSmall {
  max-height: 200px;

  @media #{$small-and-down} {
    max-height: 300px;
  }
}

.assetSizeMedium {
  max-height: 300px;

  @media #{$small-and-down} {
    max-height: 400px;
  }
}

.assetSizeLarge {
  max-height: 400px;

  @media #{$small-and-down} {
    max-height: 500px;
  }
}

.assetSizeXLarge {
  max-height: 500px;

  @media #{$small-and-down} {
    max-height: 600px;
  }
}

.legalPadding {
  &S {
    @media #{$small-and-down} {
      @include legalPadding;
    }
  }

  &M {
    @media (max-width: $large-screen) {
      @include legalPadding;
    }
  }

  &L {
    @media #{$extra-large-and-up} {
      @include legalPadding;
    }
  }
}
