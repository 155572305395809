$azFallbackFonts: 'HelveticaNeue-Condensed', 'Open Sans Condensed', Arial, sans-serif;
$helveticaFallbackFonts: 'Helvetica', Arial, 'Lucida Grande', sans-serif;
$font-az-cond: 'AutoZoneCondensed', #{$azFallbackFonts};
$font-az-cond-medium: 'AutoZoneCond-Medium', #{$azFallbackFonts};
$font-az-cond-heavy: 'AutoZoneCond-Heavy', #{$azFallbackFonts};
$font-az-cond-regular-italic: 'AutoZoneCond-Italic', #{$azFallbackFonts};
$font-az-cond-medium-italic: 'AutoZoneCond-MediumIt', #{$azFallbackFonts};
$font-az-cond-heavy-italic: 'AutoZoneCond-HeavyIt', #{$azFallbackFonts};
$font-az-helvetica: 'Helvetica Neue', #{$helveticaFallbackFonts};
$font-az-helvetica-medium: 'HelveticaNeue-Medium', sans-serif;
$font-az-helvetica-heavy: 'HelveticaNeue-Bold', sans-serif;
$font-az-helvetica-italic: 'HelveticaNeue-Italic', sans-serif;
$font-az-helvetica-light: 'Helvetica Neue', 'Helvetica-Light', 'Helvetica', sans-serif;
$font-roboto: Roboto, sans-serif;
