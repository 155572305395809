@import '@/theme/newVariables.scss';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -90px;
  margin-left: 0 !important;
  overflow: hidden;
}

.cardContentContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

  @media #{$extra-large-and-up-max} {
    padding: 0px 20px;
  }
}

.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  z-index: 2;

  .chevronImg {
    margin: 0 !important;
  }

  .chevronImgHover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 50;
    opacity: 0;
    margin: 0 !important;
    object-fit: cover;
  }

  @media (hover: hover) {
    &:hover .chevronImgHover {
      opacity: 1;
    }
  }

  &.hiddenArrow {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  &.navButtonLeft {
    left: 0;
  }

  &.navButtonRight {
    right: 0;
  }

  &.navButtonLeft {
    left: -5px;
  }

  &.navButtonRight {
    right: -10px;
  }

  @media #{$extra-large-and-up-max} {
    &.navButtonLeft {
      left: 10px;
    }

    &.navButtonRight {
      right: 10px;
    }
  }
}

.navButtonLeft {
  left: 20px;

  .chevronImg {
    transform: rotate(180deg);
  }

  .chevronImgHover {
    transform: rotate(180deg);
  }
}

.navButtonRight {
  right: 15px;
}

.cardContainer {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  position: relative;
  z-index: 1;
  padding: 3px 20px;
  width: 100%;

  @media #{$medium-and-down} {
    padding: 3px 16px;
  }

  @media #{$extra-large-and-up-max} {
    padding: 3px 0px;
  }
}

.card {
  flex-shrink: 0;
  transition: transform 0.3s ease-in-out;
}

.cardBody {
  height: 254px;
  background-color: white;
  border-radius: 4px;
  box-shadow: var(--st-shadow-2dp);
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1139px) {
    width: 188px;
  }

  @media (max-width: 719px) {
    width: 140px;
    height: 220px;
  }
}

.cardText {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 8px 12px;
  gap: 4px;
}

.heading {
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 800;
  color: $az-black;
  font-family: $font-az-cond;
  text-transform: uppercase;

  @media (max-width: 719px) {
    font-size: 14px;
    line-height: 17.5px;
  }
}

.subheading {
  font-size: 14px;
  font-family: $font-az-helvetica;
  font-weight: 400;
  line-height: 19.6px;
  color: $az-black;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 719px) {
    font-size: 12px;
    line-height: 16.8px;
  }
}

.imageContainer img {
  vertical-align: bottom;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.srOnly {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
