@import '@/theme/newVariables.scss';

.skeletonContainer {
  overflow: hidden;
  position: relative;
}
.title {
  width: $az-spacing-420;
  @media #{$small-and-down} {
    width: 347px;
  }
}
.partsContainer {
  overflow: hidden;
  flex-wrap: nowrap;
  .partCard {
    padding: 11px;
    margin-top: 0;
    margin-bottom: $az-spacing-5xs;
    row-gap: 0.5px;
    min-width: $az-spacing-158;
    max-width: $az-spacing-158;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 1px 0px rgba(0, 0, 0, 0.12),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14);
    .ctaSkeleton {
      background-color: $az-black;
    }
  }
}
.partsWithArrow {
  flex-wrap: nowrap;
  width: 100%;
}
.rightArrow {
  position: absolute;
  margin-top: -$az-spacing-22;
  right: $az-spacing-neg-6;
  display: flex;

  .chevronImg {
    position: relative;
    bottom: 150px;
    margin: 0 !important;
  }

  .chevronImgHover {
    right: 0 !important;
    left: 0 !important;
    bottom: 150px !important;
    opacity: 0;
    margin: 0 !important;
    position: absolute;
    object-fit: cover;
  }

  @media (hover: hover) {
    &:hover .chevronImgHover {
      opacity: 1;
    }
  }
}
