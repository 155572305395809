@import '@/theme/newVariables';

@mixin split-styles($split) {
  flex-basis: calc(100% / $split);
  flex-grow: 0;
  max-width: calc(100% / $split);
}

.grid-size {
  @media (min-width: $small-screen-up) {
    &-one {
      @include split-styles(1);
    }

    &-two {
      @include split-styles(2);
    }

    &-three {
      @include split-styles(3);
    }

    &-four {
      @include split-styles(4);
    }

    &-five {
      @include split-styles(5);
    }

    &-six {
      @include split-styles(6);
    }

    &-seven {
      @include split-styles(7);
    }

    &-eight {
      @include split-styles(8);
    }
  }
}
