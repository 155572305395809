$font-size-60: 60px;
$font-size-48: 48px;
$font-size-45: 45px;
$font-size-44: 44px;
$font-size-42: 42px;
$font-size-40: 40px;
$font-size-38: 38px;
$font-size-36: 36px;
$font-size-35: 35px;
$font-size-34: 34px;
$font-size-32: 32px;
$font-size-30: 30px;
$font-size-28-8: 28.8px;
$font-size-28: 28px;
$font-size-27: 27px;
$font-size-26: 26px;
$font-size-25: 25px;
$font-size-24: 24px;
$font-size-22: 22px;
$font-size-21: 21px;
$font-size-20: 20px;
$font-size-20-25: 20.25px;
$font-size-19: 19px;
$font-size-18: 18px;
$font-size-17: 17px;
$font-size-16: 16px;
$font-size-15-75: 15.75px;
$font-size-15: 15px;
$font-size-14: 14px;
$font-size-13: 13px;
$font-size-13-5: 13.5px;
$font-size-12: 12px;
$font-size-11-25: 11.25px;
$font-size-11: 11px;
$font-size-10: 10px;
$font-size-8: 8px;
$font-size-7: 7px;
$font-size-6: 6px;
$font-size-0: 0px;

// Scalable Typography sizes :: Mobile | Tablet | Desktop
$font-size-3xl: 3rem; // 48px | 54px | 60px
$font-size-xxl: 2.488rem; // 39.808 | 44.784 | 49.76
$font-size-xl: 2.074rem; // 33.184 | 37.332 | 41.48
$font-size-2base: 2rem; // 32 | 36 | 40
$font-size-lg: 1.728rem; // 27.648 | 31.104 | 34.56
$font-size-oneAndHalfBase: 1.5rem;
$font-size-md: 1.44rem; // 23.04 | 25.92 | 28.8
$font-size-sm: 1.2rem; // 19.2 | 21.6 | 24
$font-size-base: 1rem; // 16 | 18 | 20 - 12pt, 16px
$font-size-xs: 0.9375rem; // 15 | 16.875px | 18.75px
$font-size-small: 0.875rem; // 10pt, 13.333px
$font-size-xxs: 0.75rem; // 12 | 13.5px | 15px - 9pt, 12px
$font-size-half: 0.5rem;

$font-normal-weight: 400;
