$xsmall-screen: 320px !default;
$xsmall-screen-mid: 340px !default;
$xsmall-screen-up: 374px !default;
$small-screen: 719px !default;
$small-screen-up: 720px !default;
$xmedium-screen: 767px !default;
$medium-screen: 1024px !default;
$large-screen: 1139px !default;
$large-screen-up: 1140px !default;
$large-screen-mid: 1399px !default;
$large-screen-max: 1600px !default;

$xsmall-and-down: 'only screen and (max-width : #{$xsmall-screen})' !default;
$xsmall-only: 'only screen and (min-width : #{$xsmall-screen}) and (max-width : #{$xsmall-screen-up})' !default; // only mobile
$xsmall-mid-and-up: 'only screen and (min-width : #{$xsmall-screen-mid}) and (max-width : #{$xsmall-screen-up})' !default; // only mobile from 340px to 374px
$xsmall-and-up: 'only screen and (min-width : #{$xsmall-screen})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default; // only mobile
$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default; // except mobile
$medium-to-large-max: 'only screen and (min-width : #{$small-screen-up}) and (max-width: 1560px)' !default; // tablet to 1560px
$xmedium-only: 'only screen and (min-width : #{$small-screen}) and (max-width : #{$xmedium-screen})' !default; // 720px to 767px
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$large-screen})' !default; // only tablet
$small-to-medium: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default; // only tablet to 1024px
$medium-and-down: 'only screen and (max-width : #{$large-screen})' !default; // mobile and tablet
$large-and-up: 'only screen and (min-width : #{$large-screen-up})' !default; // tablet and up
$extra-large-and-down: 'only screen and (max-width : #{$large-screen})' !default; // except desktop
$extra-large-and-up: 'only screen and (min-width : #{$large-screen-up})' !default; // only desktop
$extra-large-and-up-mid: 'only screen and (min-width : #{$large-screen-up}) and (max-width : #{$large-screen-mid})' !default; // xl desktop
$large-and-down: 'only screen and (max-width : #{$large-screen-max})' !default; // desktop, mobile and tablet
$large-only: 'only screen and (min-width : #{$large-screen-up}) and (max-width : #{$large-screen-max})' !default;
$large-mid-and-up: 'only screen and (min-width : #{$large-screen-mid})' !default; // 1399px desktop and up
$extra-large-and-up-max: 'only screen and (min-width : #{$large-screen-max})' !default; // >=1600px desktop

$STARC-small-screen: 709px !default;
$STARC-medium-and-up: 'only screen and (min-width : #{$STARC-small-screen})' !default;
$STARC-small-and-down: 'only screen and (max-width : #{$STARC-small-screen})' !default; // only mobile to 709px
