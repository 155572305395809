@import '../../../theme/newVariables';

$orange-border-width: $az-spacing-3;

.carIcon {
  width: $az-spacing-xxs;
  box-sizing: content-box;
  padding-right: $az-spacing-4xs;
}

.downIcon {
  width: $az-spacing-13;
  height: $az-spacing-13;
  transform: rotate(-90deg);
  box-sizing: content-box;
  position: absolute;
  right: $az-spacing-4xs;
}

.addVechiclePart {
  margin-right: $az-spacing-19;
  height: $az-spacing-43;
  display: inline-block;
  background: $az-white;
  box-shadow: $az-spacing-zero $az-spacing-1 $az-spacing-3 $az-spacing-zero rgba($az-black, 0.2);
  position: relative;
  text-decoration: none;
  color: $az-black;
  cursor: pointer;
  width: $az-percent-100;

  .carIcon {
    width: $az-spacing-18;
    padding: $az-spacing-4xs;
    box-sizing: content-box;
    margin-top: $az-spacing-5;
  }

  .downIcon {
    width: $az-spacing-s;
    float: $rdirection;
    padding: $az-spacing-9;
    box-sizing: content-box;
    position: absolute;
    right: $az-spacing-zero;
  }

  span {
    font-size: $font-size-15;
    color: $az-grey-3;
    vertical-align: top;
    line-height: $az-spacing-43;
  }
}

.mobileToggle {
  @media #{$xsmall-and-down} {
    display: none;
  }

  @media (min-width: 0) {
    display: none;
  }
}

@mixin mobileMixin {
  flex-direction: column;

  .ymmeRegion {
    border-radius: $az-spacing-6;

    .ymmeSelectBox {
      height: $az-spacing-60;
    }
  }
}

.ymmeRow {
  display: flex;
  gap: $az-spacing-xs;

  @media #{$small-and-down} {
    @include mobileMixin();
  }

  &.forceMobile {
    @include mobileMixin();
    gap: $az-spacing-xxs;
  }
}

.ymmeRegion {
  flex: 1;
  display: flex;
  justify-content: center;
  height: $az-spacing-xxl;

  .ymmeSelectBox {
    position: relative;
    width: 100%;

    ul {
      margin-top: 0;

      @media #{$medium-only} {
        margin-top: $az-spacing-4xs;
      }
    }
  }
}

.ymmeSearchContainer {
  @media #{$medium-only} {
    margin: $az-spacing-zero $az-spacing-neg-20;
  }

  @media #{$small-and-down} {
    margin: $az-spacing-zero $az-spacing-neg-16;
  }
}

.ymmeContainer {
  background: $az-grey-1;
  padding: var(--st-unit-4);

  @media #{$large-and-up} {
    padding: 30px var(--st-unit-4);
  }

  box-shadow: inset $az-spacing-zero $az-spacing-3 $az-spacing-zero $az-spacing-zero
    $az-medium-orange;

  .ymmeTopContainer {
    background: $az-grey-1;
    padding: $az-spacing-zero;

    &:lang(pt) {
      flex-direction: column;
    }
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
  }

  .ymmetitleTextStyles {
    padding-top: 8px;
    font-family: $font-az-cond;
    font-size: 26px;
    line-height: 28px;
    text-transform: uppercase;

    @media #{$medium-and-down} {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .ymmeTitle {
    color: var(--st-color-primary);
    font-family: var(--st-font-family-headline);
    font-size: var(--st-unit-4);
    font-weight: var(--st-font-weight-heavy);
    font-style: normal;
    line-height: 125%;
    letter-spacing: 0.6px;
    text-transform: uppercase;

    div {
      font-size: $font-size-21;
    }

    @media #{$medium-only} {
      height: 28px;
    }

    &.ymmeTitleMaxWidth {
      max-width: 122px;
    }
  }

  .ymmeSubTitle {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
  }

  .selectContainer {
    flex-grow: 1;
  }

  .titleDesc {
    @media #{$extra-large-and-up} {
      font-size: $font-size-14;
      padding-top: $az-spacing-5xs;
    }

    @media #{$small-and-down} {
      max-width: $az-spacing-294;
      margin: $az-spacing-zero auto;
    }
  }

  .makeOrModelName {
    text-transform: capitalize;
  }

  @media #{$small-and-down} {
    margin-bottom: $az-spacing-l;

    .titleContainer {
      text-align: center;
    }
  }

  @media #{$medium-only} {
    &.ymmeContainerSelectedPDP {
      height: 140px;
    }

    padding: var(--st-unit-2) var(--st-unit-4) var(--st-unit-3);
    .ymmeTopContainer {
      .titleContainer {
        padding-bottom: $az-spacing-4xs;
      }
    }
  }

  @media #{$extra-large-and-up} {
    .ymmeTopContainer {
      display: flex;
    }

    .titleContainer {
      padding-bottom: $az-spacing-zero;
    }

    .setVehicleTitle {
      margin-right: $az-spacing-xxs;
      max-width: $az-spacing-137;
      min-width: $az-spacing-137;

      &:lang(pt) {
        max-width: 100%;
        margin-bottom: $az-spacing-xxs;
      }
    }
  }

  .alignCenter {
    text-align: $cdirection;
  }

  .overridePadding {
    padding: $az-spacing-1 $az-spacing-zero;
  }

  .ymmeTitleColor {
    color: $az-grey-6;
  }

  .ymmeLabel {
    margin-bottom: $az-spacing-xxs;
    font-weight: 900;

    @media #{$small-and-down} {
      margin-top: $az-spacing-neg-8;
    }
  }

  .ymmeDesktopTitle {
    width: $az-percent-100;
    align-self: center;
  }

  .ymmeVehicleSelection {
    font-family: $font-az-helvetica;
    font-size: $font-size-14;
    line-height: 1.5;
    color: $az-grey-6;
    width: $az-percent-100;
    display: flex;
    align-items: flex-start;
    text-align: $ldirection;
    background-color: $az-white;
    box-shadow: $az-spacing-1 $az-spacing-2 $az-spacing-5xs $az-spacing-zero rgba($az-black, 0.2),
      inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-medium-orange;

    @media #{$small-and-down} {
      width: $az-percent-94;
      padding: $az-spacing-xxs;
    }

    @media #{$medium-only} {
      padding: $az-spacing-xxs;
    }

    @media #{$extra-large-and-up} {
      padding: $az-spacing-xs;
    }
    &:hover {
      background-color: $az-white;
    }
  }

  .changeVehicleButton,
  .shopWithoutVehicle {
    font-family: $font-az-helvetica;
    font-size: $font-size-16;
    line-height: 1.5;
  }

  .noPreferedvehicleIcon,
  .preferedvehicleIcon {
    width: $az-spacing-m;
    height: $az-spacing-m;
    margin: $az-spacing-5 $az-spacing-22 $az-spacing-5 $az-spacing-2;

    @media #{$extra-large-and-up} {
      margin: $az-spacing-5 $az-spacing-21 $az-spacing-5 $az-spacing-3;
    }

    @media #{$small-and-down} {
      margin-top: $az-spacing-10;
    }
  }

  .vehicleImage {
    width: $az-spacing-60;
    height: $az-spacing-28;
  }

  .preferedvehicleIcon,
  .changeVehicleLink {
    display: none;
  }

  .vehicleSelected {
    @media #{$medium-and-up} {
      display: flex;
      justify-content: space-around;
    }

    .changeVehicleLink {
      display: flex;
      align-items: baseline;
      flex-wrap: nowrap;

      button,
      span {
        flex-shrink: 0;
        padding-left: $az-spacing-1;
        text-decoration: underline;
        text-transform: capitalize;
        width: fit-content;
        margin-right: $az-spacing-s;
        padding-bottom: $az-spacing-zero;

        @media #{$medium-only} {
          padding-right: $az-spacing-zero;
          white-space: nowrap;
          margin-right: $az-spacing-14;
        }
      }

      @media #{$medium-only} {
        padding-top: $az-percent-5;
      }
    }

    .preferedvehicleIcon {
      width: $az-spacing-36;
      display: block;
    }

    .noPreferedvehicleIcon {
      display: none;
    }

    .ymmeLabel {
      @media #{$medium-and-up} {
        font-size: $font-size-20;
      }

      @media #{$small-and-down} {
        white-space: nowrap;
      }
    }
  }

  .ymmeVehicleSet {
    height: $az-spacing-46;
    padding-top: $az-spacing-l;
    max-width: $az-spacing-360;

    &.ymmeTitleColor {
      height: auto;
    }

    @media #{$medium-and-down} {
      padding-top: $az-spacing-zero;
    }
  }
}

#shopWithoutVehicle {
  margin-left: $az-spacing-s;
}

.ymmeErrorMsg {
  color: $az-light-red;
  margin: $az-spacing-10 $az-spacing-zero;
  font-size: $font-size-15;
}

.vehicleImage {
  width: $az-spacing-60;
  height: $az-spacing-28;
}

.vehicleIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--st-unit-6);
  height: var(--st-unit-6);
}

.vehicleIcon,
.vehicleImage {
  fill: $az-light-orange;
  margin: $az-spacing-9 $az-spacing-9 $az-spacing-9 $az-spacing-zero;
  flex-shrink: $az-spacing-zero;

  @media #{$extra-large-and-up} {
    margin: $az-spacing-9 $az-spacing-9 $az-spacing-9 $az-spacing-xxs;
  }

  @media #{$small-and-down} {
    height: auto;
    width: $az-spacing-18;
  }
}

.vehicleIconContainer {
  cursor: pointer;
  padding: var(--st-unit-1) var(--st-unit-4) var(--st-unit-1)
    calc(var(--st-unit-4) - $orange-border-width);
  height: 62px;
  background-color: $az-white;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: $cdirection;
  margin: $az-spacing-zero $az-spacing-xs;
  box-shadow: 0 $az-spacing-1 $az-spacing-3 0 rgba(0, 0, 0, 0.2);
  width: $az-spacing-500;
  min-width: $az-spacing-230;
  border-left: $orange-border-width solid $az-medium-orange;

  @media #{$extra-large-and-up} {
    padding: 10px var(--st-unit-4) 10px calc(var(--st-unit-4) - $orange-border-width);
  }
}

.currentlyShoppingContainer {
  max-width: $az-spacing-110;

  @media #{$extra-large-and-down} {
    max-width: $az-spacing-124;
  }
}

.ymmeOptionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  display: flex;
  align-items: center;
  font-size: $font-size-14;
  color: $az-grey-6;
  font-weight: normal;
  min-width: $az-spacing-55;

  @supports (-webkit-line-clamp: 2) {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.vehicleImageStyles {
  margin-right: $az-spacing-xxs;
  width: $az-spacing-60;
  height: $az-spacing-28;
}

.noVehicleImageStyles {
  margin-right: $az-spacing-xxs;
}

.hasVehicle {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: $az-spacing-s;
  align-items: $cdirection;
}

.noMargin {
  margin-bottom: $az-spacing-zero;
}

.vehicleLink {
  height: var(--st-unit-11);
  padding: 0;
  font-size: var(--st-font-size-087);
  color: var(--st-color-gray-600);
  font-weight: var(--st-font-weight-medium);
  text-decoration: underline;
  text-transform: none;
  line-height: 140%;
  font-family: var(--st-font-family-body);
  cursor: pointer;
}

.vehicleInfoCard {
  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-33;
  }

  @media #{$small-and-down} {
    margin: 0 $az-spacing-xxs;
  }
}

.ymmeMobileContainer {
  box-shadow: $az-spacing-1 $az-spacing-2 $az-spacing-5xs $az-spacing-zero rgba(0, 0, 0, 0.2);
  border-left: $az-spacing-3 solid $az-medium-orange;
  border-radius: 4px;
  background-color: $az-white;
  padding: $az-spacing-xxs;
  margin-top: $az-spacing-xxs;
}

.bottomSpacing {
  margin-bottom: $az-spacing-xxs;
}

.selectVehicle {
  background-color: $az-white !important;
  box-shadow: $az-spacing-1 $az-spacing-1 $az-spacing-5xs $az-spacing-zero rgba(0, 0, 0, 0.2);
  padding: $az-spacing-10 $az-spacing-xxxs !important;
}

.vehicleLabel {
  color: $az-grey-3;
  line-height: $az-spacing-xs;
}

.ymmeContainerSelected {
  padding-top: $az-spacing-46;
  padding-bottom: $az-spacing-46;

  @media #{$medium-only} {
    padding-bottom: $az-spacing-m;
  }

  @media #{$small-and-down} {
    padding: $az-spacing-m $az-spacing-xxs $az-spacing-28;
  }
}

.vehicleGridItem {
  @media #{$extra-large-and-up} {
    min-width: 35.7%;
  }
}

.desktopWrapper {
  width: 100%;
}

.ymmeContentContainer {
  padding: $az-spacing-zero $az-spacing-zero $az-spacing-xxxl $az-spacing-zero;

  @media #{$medium-only} {
    padding-bottom: $az-spacing-l;
  }
}

.image {
  object-fit: contain;
}

.noMargins {
  margin: 0;
}
