@import '@/theme/newVariables';

.headlineContainer {
  padding-bottom: var(--st-unit-8);
}

.reducedSpacing {
  padding-bottom: var(--st-unit-5);
}

.bannerHeadline {
  padding-bottom: var(--st-unit-6);
}

.noGutter {
  padding: 0;
}

.fullWidthHorizontalPadding {
  padding: 0 var(--st-unit-4) var(--st-unit-8);

  @media #{$large-and-up} {
    padding: 0 var(--st-unit-5) var(--st-unit-8);
  }
}

.subHeading {
  margin-top: var(--st-unit-3);
}
