@import '@/theme/newVariables';

.superDuperHeroContainer {
  padding-bottom: 18px;

  @media #{$STARC-medium-and-up} {
    max-width: 1560px;
    margin: 0 auto 10px;
  }

  @media #{$small-and-down} {
    margin-bottom: -34px;
  }
}

.heroBanner {
  padding-bottom: 0px;
  display: flex;
  justify-content: center;

  @media #{$small-and-down} {
    margin-bottom: 0px;
  }
}

.carouselImage {
  height: $az-spacing-400 !important;
}

.carouselImageXL {
  @media #{$STARC-medium-and-up} {
    height: $az-spacing-480 !important;
  }
}

.superDuperHero {
  position: relative;
  height: 629px;

  &:hover > button {
    background: $az-grey-1;
  }
}

.carouselRoot {
  @media #{$large-and-up} {
    height: $az-spacing-480;
  }
}

.heroBanner .superDuperHero {
  height: 400px;

  @media #{$large-and-up} {
    flex-basis: 1140px;
    max-width: 1560px;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.heroBanner .carouselImage {
  height: 400px;
}

.horizontalScrollCard {
  position: absolute;
  top: 328px;
  height: $az-spacing-257;
  overflow: hidden;
  text-align: center;
  width: 100%;
  background: transparent;

  ul {
    padding: 0;
  }
}

.scrollCardItem {
  height: 240px;
  border-radius: 4px;
}
