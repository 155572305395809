@import '@/theme/newVariables';

.bannerContainer {
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
}

.bannerImageLink {
  outline: none;
  width: 100%;
  height: 100%;
}

.bannerImageLink:focus img {
  outline: none;
  outline-offset: -2px;
}

.bannerImageLink:active img {
  outline: none;
}

.prebakedImageBackground {
  width: 100%;
  height: calc(var(--st-unit-1) * 120);
  position: relative;

  @media #{$STARC-medium-and-up} {
    height: 336px;
  }

  @media #{$extra-large-and-up} {
    height: $az-spacing-480;
  }
}

.legacyBanner {
  position: relative;
  margin: $az-spacing-10 $az-spacing-xs $az-spacing-zero $az-spacing-xs;
  min-height: $az-spacing-l;
}

@media #{$small-and-down} {
  .legacyBanner {
    margin-left: $az-spacing-zero;
  }
}

.carouselImage {
  object-fit: cover;
  height: 100%;
}

.carouselImage {
  outline: none;
}
