@import '../../../theme/newVariables';

.dynamicContent {
  font-family: $font-az-helvetica;
  font-size: $font-size-14;

  h2,
  h4 {
    font-family: $font-az-helvetica;
    font-size: $font-size-16;
  }
}

.sponsoredLabel {
  text-transform: capitalize;
}

.secondBannerStyles {
  padding: $az-spacing-4xs $az-spacing-zero $az-spacing-zero !important;
  margin-bottom: $az-spacing-xxs;

  @media #{$small-and-down} {
    padding: $az-spacing-4xs $az-spacing-4xs $az-spacing-zero $az-spacing-4xs !important;
    margin-bottom: $az-spacing-xxs;
  }
}

.quotientCatBanner {
  padding-left: $az-spacing-zero !important;
  margin-bottom: $az-spacing-xs;

  @media #{$medium-and-up} {
    margin-right: $az-spacing-zero;
    margin-left: $az-spacing-zero;
    padding-right: $az-spacing-zero !important;
  }

  @media #{$small-and-down} {
    padding-right: $az-spacing-zero !important;
  }
}

.legalTextAvailable {
  margin-bottom: 4px !important;
}

.withQuotient {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @media #{$medium-and-up} {
    padding: $az-spacing-4xs $az-spacing-6 $az-spacing-zero $az-spacing-zero;
  }

  @media #{$small-and-down} {
    padding: $az-spacing-4xs $az-spacing-4xs $az-spacing-4xs $az-spacing-zero;
  }
}

.legalContainer {
  margin-top: $az-spacing-4xs;
}

.legalLink {
  color: $az-grey-5 !important;
  text-decoration: underline;
  cursor: pointer;
}

.displayBlock {
  display: block;
}

.leftMarginLargeScreens {
  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-120;
  }
}

.displayFlex {
  display: flex;
}

.quotientPipe {
  padding-right: $az-spacing-4xs;
}

.quotientText {
  margin-left: $az-spacing-s;

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-zero;
  }
}

.singleLineTextContainer {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  gap: var(--st-unit-1);
}
.singleLineLegalText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.legalText,
.quotientText {
  color: $az-grey-11;
  width: $az-percent-100;
}

.detailsButton {
  color: $az-grey-5;
}

.darkerText {
  color: $az-grey-5;
}

.heroCarouselBanner {
  margin: $az-spacing-10 $az-spacing-xs $az-spacing-4xs $az-spacing-xs;

  .quotientText {
    margin-left: $az-spacing-zero;
  }
}

.heroCarouselBannerCMS {
  min-height: var(--st-unit-4);
  margin-left: var(--st-unit-4);
  margin-right: var(--st-unit-4);

  @media #{$STARC-medium-and-up} {
    margin: 0;
  }
}

.superDuperHero {
  @media #{$small-and-down} {
    margin: 0;
  }
}
