@import '@/theme/newVariables.scss';

.separator {
  width: $az-spacing-100;
  height: $az-spacing-5xs;
  margin-top: $az-spacing-4xs;
  background-color: $az-medium-orange;
  transform: skewX(-30deg);

  @media #{$small-and-down} {
    width: $az-spacing-4xl;
  }
}

.emphasizedText {
  margin-left: 0.4rem;
}

.headline {
  font-family: $font-az-cond;
  @media #{$medium-only} {
    font-size: $font-size-36;
  }
}
