@import '../../../../theme/newVariables';

.container {
  align-self: stretch;
  border-top: #f26100 3px solid;
  background-color: $az-grey-1;
}

.button {
  display: flex;
  align-items: center;
  gap: $az-spacing-4xs;
  padding: 0 $az-spacing-xxxs;
  border-right: $az-spacing-1 solid $az-grey-16;
  height: $az-spacing-42;
  background-color: $az-white;
  font-size: $font-size-14;
  font-weight: 400;
}

.chevronIcon {
  flex-grow: 1;
}
