@import '@/theme/newVariables';

// !important used in order to overwrite cards class from CertonaPartsCarousel.tsx internal styles.
// Would be good to get rid of internal styles and usage of !important.
.cardsContainer {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: var(--st-unit-5) !important;
  min-height: initial !important;

  @media #{$medium-and-up} {
    margin-top: var(--st-unit-6) !important;
  }
}

.skinnyRecommendations {
  margin-bottom: $az-spacing-l;

  @media #{$large-and-up} {
    margin-bottom: $az-spacing-xxxl;
  }
}
