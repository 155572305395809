@import '@/theme/newVariables.scss';

@mixin horizontalSpacingRemoved {
  width: 100%;
  max-width: $large-screen-max;
  padding: 0;
}

@mixin verticalSpacingRemoved {
  margin: 0 auto;
}

.container {
  max-width: $large-screen-max;
  margin: var(--st-unit-10) auto;
  padding: 0 var(--st-unit-4);

  @media #{$medium-and-up} {
    padding: 0 var(--st-unit-5);
  }

  @media #{$large-and-up} {
    margin: var(--st-unit-16) auto;
  }

  &.landingPage {
    margin: var(--st-unit-8) auto;
    scroll-margin-top: $az-spacing-100;

    @media #{$medium-and-down} {
      margin: var(--st-unit-5) auto;
    }
  }

  &.homePage {
    margin: var(--st-unit-5) auto;
    padding: 0 var(--st-unit-4);

    @media #{$medium-and-up} {
      margin: var(--st-unit-6) auto;
      padding: 0 var(--st-unit-4);
    }

    @media #{$large-and-up} {
      margin: var(--st-unit-8) auto;
      padding: 0 var(--st-unit-5);
    }
  }

  &.shelfPage {
    margin: var(--st-unit-4) auto;
  }

  &.removeHorizontalSpacing {
    @include horizontalSpacingRemoved;

    &S {
      @media #{$small-and-down} {
        @include horizontalSpacingRemoved;
      }
    }

    &M {
      @media (max-width: $large-screen) {
        @include horizontalSpacingRemoved;
      }
    }

    &L {
      @media #{$extra-large-and-up} {
        @include horizontalSpacingRemoved;
      }
    }
  }

  &.removeVerticalSpacing {
    @include verticalSpacingRemoved;

    &S {
      @media #{$small-and-down} {
        @include verticalSpacingRemoved;
      }
    }

    &M {
      @media (max-width: $large-screen) {
        @include verticalSpacingRemoved;
      }
    }

    &L {
      @media #{$extra-large-and-up} {
        @include verticalSpacingRemoved;
      }
    }
  }
}

.reducedSpacing {
  margin: 0 auto var(--st-unit-4) auto;

  @media #{$small-and-down} {
    margin-top: var(--st-unit-4);
  }
}

.shouldUseTopCategorySpacing {
  margin: 0 auto;
  margin-bottom: -44px;
  margin-top: var(--st-unit-2);

  @media #{$small-and-down} {
    margin-bottom: -24px;
  }
}

.titleSpacing {
  margin: 0 auto var(--st-unit-4) auto;

  @media #{$small-and-down} {
    margin-top: var(--st-unit-4);
  }
}

// create a 64px gap between certona containers
.container + .container:not(.ignoreCarouselSpacing) > div > div[data-testid='carousel'] {
  margin-top: $az-spacing-xxxl;

  @media #{$medium-and-down} {
    margin-top: var(--st-unit-10);
  }
}
.container.ignoreCarouselSpacing > div[data-testid='carousel'] > div {
  margin-top: $az-spacing-zero;
}

// for mobile, create a 40px space after the last certona container
.container:has(> div > div[data-testid='carousel'])
  + :not(:has(> div > div[data-testid='carousel'])) {
  @media #{$small-and-down} {
    margin-top: 40px;
  }
}

.removeImagePadding {
  &S {
    @media #{$small-and-down} {
      picture {
        margin: 0px -20px;
      }
    }
  }

  &M {
    @media (max-width: $large-screen) {
      picture {
        margin: 0px -20px;
      }
    }
  }

  &L {
    @media #{$extra-large-and-up} {
      picture {
        margin: 0px -20px;
      }
    }
  }
}
